<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-form-datepicker
          :value="filters.timeFrom"
          :locale="this.$i18n.locale"
          @input="onChangeFilter('timeFrom', $event)"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-form-datepicker
          :value="filters.timeTo"
          :locale="this.$i18n.locale"
          @input="onChangeFilter('timeTo', $event)"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-overlay :show="areItemsLoading">
          <v-select
            multiple
            :value="filters.goods"
            :clearable="false"
            :options="availableItems"
            :getOptionLabel="x => `${x.title[$i18n.locale]} - ${x.code}`"
            @input="onChangeFilter('goods', $event)"
            :placeholder="$t('transactions.load-history-searcher.goods-select-placeholder')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-overlay :show="areUsersLoading">
          <v-select
            multiple
            :value="filters.users"
            :clearable="false"
            :options="availableUsers"
            label="userName"
            @input="onChangeFilter('users', $event)"
            :placeholder="$t('transactions.load-history-searcher.driver-select-placeholder')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-overlay :show="areVehiclesLoading">
          <v-select
            multiple
            :value="filters.vehicles"
            :clearable="false"
            :options="availableVehicles"
            label="registrationNumber"
            @input="onChangeFilter('plates', $event)"
            :placeholder="$t('transactions.load-history-searcher.vehicle-select-placeholder')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@/services/api.js";
import { DRIVER, MANAGER } from "@/const/user-role.js";

export default {
  data() {
    var dateMonthBefore = new Date();
    dateMonthBefore.setMonth(dateMonthBefore.getMonth() - 1);
    return {
      // NOTE should be changed in ~8k years to .slice(0, 11)
      timeFrom: dateMonthBefore.toISOString().slice(0, 10),
      timeTo: new Date().toISOString().slice(0, 10),
      areItemsLoading: false,
      areUsersLoading: false,
      areVehiclesLoading: false,
      availableItems: [],
      availableUsers: [],
      availableVehicles: []
    };
  },
  computed: {
    filters() {
      return this.$store.getters["loadHistoryTransactions/filters"];
    }
  },
  methods: {
    onChangeFilter(property, value) {
      this.$store.dispatch("loadHistoryTransactions/setFilter", { property, value });
    }
  },
  mounted() {
    this.areItemsLoading = true;
    this.areUsersLoading = true;
    this.areVehiclesLoading = true;

    Promise.all([
      api.getGoodsWithoutSn(),
      api.getUsersNamesByRoles([DRIVER, MANAGER]),
      api.getVehiclesRegistrationNumbers()
    ])
      .then(resp => {
        this.availableItems = resp[0].data;
        this.availableUsers = resp[1].data;
        this.availableVehicles = resp[2].data;
      })
      .catch(() => alert(this.$t("transactions.load-history-searcher.error.general")))
      .finally(() => {
        this.areItemsLoading = false;
        this.areUsersLoading = false;
        this.areVehiclesLoading = false;
      });
  }
};
</script>
