<style scoped>
.checkbox {
  text-align: center;
}
</style>

<template>
  <b-overlay :show="$store.state.loadHistoryTransactions.isLoading">
    <b-table
      ref="transaction-table"
      show-empty
      :fields="fields"
      :items="transactionHistory"
      @sort-changed="onChangedSort"
      :empty-text="$t('transactions.general-result.table.empty-text')"
      responsive
      no-local-sorting
    >
      <template slot="top-row">
        <td></td>
        <td>
          <b-input :value="filters.event" @change="onSetFilter({ property: 'event', value: $event.trim() })"></b-input>
        </td>
        <td>
          <b-input :value="filters.user" @change="onSetFilter({ property: 'user', value: $event.trim() })"></b-input>
        </td>
        <td>
          <b-input
            :value="filters.vehicle"
            @change="onSetFilter({ property: 'vehicle', value: $event.trim() })"
          ></b-input>
        </td>
        <td>
          <b-input
            :value="filters.goodsTitle"
            @change="onSetFilter({ property: 'goodsTitle', value: $event.trim() })"
          ></b-input>
        </td>
        <td></td>
        <td></td>
      </template>
      <template v-slot:cell(date)="row">
        {{ $d(Date.parse(row.item.date)) }}
      </template>
      <template v-slot:cell(user)="row">
        {{ row.item.user.userName }}
      </template>
      <template v-slot:cell(vehicle)="row">
        {{ row.item.vehicle.registrationNumber }}
      </template>
      <template v-slot:cell(goodsInfo)="row">
        {{ row.item.goodsInfo.title[$i18n.locale] }}
      </template>
      <template v-slot:cell(balance)="row">
        {{ row.item.balance }}
      </template>
    </b-table>
    <b-pagination
      :value="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :per-page="pagination.limit"
      @change="$store.dispatch('loadHistoryTransactions/setCurrentPage', $event)"
    ></b-pagination>
  </b-overlay>
</template>

<script>
export default {
  computed: {
    transactionHistory() {
      return this.$store.getters["loadHistoryTransactions/transactions"];
    },
    filters() {
      return this.$store.getters["loadHistoryTransactions/filters"];
    },
    fields() {
      return [
        { key: "date", label: "Date", sortable: true },
        { key: "event", label: "Event", sortable: true },
        { key: "user", label: "User", sortable: true },
        { key: "vehicle", label: "Plate number", sortable: true },
        { key: "goodsInfo", label: "Item", sortable: true },
        { key: "quantity", label: "Quanitity", sortable: false },
        { key: "balance", label: "Balance", sortable: false }
      ];
    },
    pagination() {
      return this.$store.getters["loadHistoryTransactions/pagination"];
    }
  },
  methods: {
    onChangedSort(ctx) {
      this.$store.dispatch("loadHistoryTransactions/setSort", ctx);
    },
    onSetFilter(e) {
      this.$store.dispatch("loadHistoryTransactions/setFilter", e);
    }
  },
  mounted() {
    this.$store.dispatch("loadHistoryTransactions/loadTransactions");
  }
};
</script>
