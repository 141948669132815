<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <load-history-searcher></load-history-searcher>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <load-history-exporter></load-history-exporter>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <load-history-search-result></load-history-search-result>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LoadHistorySearcher from "@/components/transactions/LoadHistorySearcher.vue";
import LoadHistoryExporter from "@/components/transactions/LoadHistoryExporter.vue";
import LoadHistorySearchResult from "@/components/transactions/LoadHistorySearchResult.vue";

export default {
  components: {
    LoadHistorySearcher,
    LoadHistoryExporter,
    LoadHistorySearchResult
  },
  mounted() {
    this.$store.commit("transactions/clear");
  }
};
</script>
